@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');


*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  /* color: white !important; */
}

.form-select:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
  
}

body {
  margin: 0;
  padding: 0;
  font-family:'Noto-Sans' !important; 
  font-style: normal;
  background-color: #f6f7f9;
}

h1 {
  margin: 0;
  font-size: 15px;
  line-height: 1;
}

.btn-outline-primary1 {
  color: white;
}
.btn-outline-primary1:hover {
  color: white;
  background-color: #1473e6;
}

.btn-primary1 {
  color: #fff;
  background-color: #e12454;
  border-color: #e12454;
}
.btn-primary1:hover {
  color: #fff;
  background-color: #e12454;
  border-color: #e12454;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #e12454 0%,
    #e124548a 51%,
    #e12454e3 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

button {
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  cursor: pointer;
}
button.chatbox-open {
  position: fixed;
  bottom: 0px;
  right:3px;
  width: 52px;
  height: 52px;
  color: #fff;
  background: #e12454;
  background: linear-gradient(to bottom right, #e12454, #e12454db);
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px;
}
button.chatbox-close {
  position: fixed;
  bottom: 10;
  right: 10;
  width: 52px;
  height: 52px;
  color: #fff;
  background: #e12454;
  background: linear-gradient(to bottom right, #e12454, #e12454db);
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  /* display: none; */
  margin: 15px calc(2 * 15px + 52px) 15px 15px;
  display: none; 
  /* optional added for requirment */

}

textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: calc(15px + 15px / 2);
  padding: 0 calc(15px / 2);
  font-size: 13px;
  line-height: calc(15px + 15px / 2);
  color: #888;
  background-color: none;
  border: 0;
  outline: 0 !important;
  resize: none;
  overflow: hidden;
}
textarea::-moz-placeholder {
  color: #888;
}
textarea:-ms-input-placeholder {
  color: #888;
}
textarea::placeholder {
  color: #888;
}

.chat-first-form input,
.chat-first-form .form-select {
  height: 35px;
}
.chat-first-form .form-select,
.chat-first-form label {
  font-size: 14px;
}

.chatbox-popup {
  display: flex;
  position: fixed;
  z-index: 9999;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
  bottom: calc(2 * 15px + 52px);
  right: 15px;
  width: 345px;
  max-height: 85vh;
  background-color: #fff;
  border-radius: 15px;
}
.chatbox-popup .chatbox-popup__header {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  color: #fff;
  background: #e12454;
  background: linear-gradient(to bottom right, #e12454, #e12454db);
  align-items: center;
  justify-content: space-around;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.chatbox-popup .chatbox-popup__header .chatbox-popup__avatar {
  background-color: #e12454;
  border: 5px solid rgba(0, 0, 0, 0.1019607843);
  border-radius: 50%;
}
.chatbox-popup .chatbox-popup__header .head-logo {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.1019607843);
  background: white;
}

.chatbox-popup .chatbox-popup__main {
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * 15px) 15px;
  line-height: calc(15px + 15px / 2);
  color: #888;
  text-align: center;
  height: 67vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: white;
}
.chatbox-popup .chatbox-popup__main::-webkit-scrollbar {
  display: none;
}
.chatbox-popup .chatbox-popup__footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 7px 10px;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: space-around;
  /* border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
}

.chatbox-panel {
  display: flex;
  position: absolute;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  width: 345px;
  background-color: #fff;
}
.chatbox-panel .chatbox-panel__header {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 15px;
  color: #fff;
  background: #e12454;
  background: linear-gradient(to bottom right, #e12454, #e12454db);
  align-items: center;
  justify-content: space-around;
  flex: 0 0 auto;
}
.chatbox-panel .chatbox-panel__header .head-logo {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.1019607843);
  background: white;
}
.chatbox-panel .chatbox-panel__header .head-logo img {
  width: 40px;
}
.chatbox-panel .chatbox-panel__main {
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * 15px) 15px;
  line-height: calc(15px + 15px / 2);
  color: #888;
  text-align: center;
  max-height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
  flex: 1 1 auto;
  padding: 16px 15px;
  max-height: 88vh;
}
.chatbox-panel .chatbox-panel__main::-webkit-scrollbar {
  display: none;
}
.chatbox-panel .chatbox-panel__main .chat-box-body .chat-logs .messages {
  height: 74vh !important;
  max-height: 74vh;
}
.chatbox-panel .chatbox-panel__main .chatbox-popup__footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 15px;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: space-around;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.chatbox-panel .chatbox-panel__footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 15px;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: space-around;
  flex: 0 0 auto;
}

.chat-box-overlay {
  background: #b2c3e8;
  display: flex;
  align-items: center;
  padding: 10px;
}
.chat-box-overlay .img_box {
  width: 11%;
}
.chat-box-overlay .img_box img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #fff;
}
.chat-box-overlay .info-box {
  width: 60%;
  color: white;
}
.chat-box-overlay .info-box h3 {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 2px;
}
.chat-box-overlay .info-box .profile-status {
  color: #fff;
  font-size: 11px;
}

.chat-box-overlay .info-box .profile-status span {
  float: left;
  width: 10px;
  height: 10px;
  background: #04e204;
  display: block;
  border: 1px solid #106500;
  border-radius: 5px;
  margin: 3px 3px 0 0;
}

.chat-box-body ::-webkit-scrollbar {
  background: white;
  display: none;
}
.chat-box-body .chat-logs {
  flex-shrink: 2;
  overflow-y: auto;
}
.chat-box-body .chat-logs .messages {
  background: #f7f7f7;
  /* padding: 5px; */
  overflow: hidden;
  height: 57.5vh;
  overflow-y: auto;
}
.chat-box-body .chat-logs .time {
  font-size: 10px;
  background: #eee;
  padding: 2px 14px;
  border-radius: 7rem;
  color: #0b3363;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.chat-box-body .chat-logs .user-chat-main {
  margin: 8px 0 4px 0;
}
.chat-box-body .chat-logs .user-chat-main .user-chat {
  padding: 10px 20px;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  /* Created with https://www.css-gradient.com */
  background: #79c8e7;
  background: linear-gradient(to bottom right, #e12454, #e12454db);
  color: white;
  font-size: 13px;
  max-width: 90%;
  text-align: left;
  margin: 5px;
}

.chat-box-body .chat-logs .bot-chat-main {
  margin: 30px 0 4px 0;
}
.chat-box-body .chat-logs .bot-chat-main .bot-chat {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: white;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  min-height: 2.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
  max-width: 120%;
  text-align: left;
}

.typing {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0rem;
  box-sizing: border-box;
  background: #e12454;
  border-radius: 50%;
}

.agentSide-typing {
  background: #0d6efd !important;
}
.typing.typing-1 {
  -webkit-animation: typing 3s infinite;
  animation: typing 3s infinite;
}
.typing.typing-2 {
  -webkit-animation: typing 3s 250ms infinite;
  animation: typing 3s 250ms infinite;
}
.typing.typing-3 {
  -webkit-animation: typing 3s 500ms infinite;
  animation: typing 3s 500ms infinite;
}

@-webkit-keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}
.chat-message__avatar-frame {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b2c3e8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1473e6;
  border: 1px solid #1473e6;
}
::-webkit-scrollbar-thumb:hover {
  background: #e12454;
  border-color: #e12454;
} /*# sourceMappingURL=style.css.map */

.timer_bot_right {
  text-align: right;
  margin: 0 5px;
  font-size: 10px;
  justify-content: flex-end !important;
  padding: 0;
}

.timer_bot_left {
  text-align: left;
  margin: 0 0 0 50px;
  font-size: 10px;
  justify-content: flex-start !important;
  padding: 0;
}

.company-name h4 {
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 15px !important;
}

.company-name span {
  font-size: 10px !important;
}

.startChat {
  padding: 10px !important;
  margin: 0 auto !important;
  background: #e12454 !important;
  color: #fff !important;
}
.startvideo {
  padding: 10px !important;

  background: #e12454 !important;
  color: #fff !important;
}
.closeIconChat {
  margin-right: -10px;
}

.call-animation {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  animation: call 1.5s ease infinite;
}

/* @keyframes call {
  15% {
    box-shadow: 0 0 0 4px #e124547a;
  }
  25% {
    box-shadow: 0 0 0 5px #e1245357, 0 0 0 16px #e124532f;
  }
  30% {
    box-shadow: 0 0 0 8px #e124547a, 0 0 0 24px #e124532f;
  }
} */

.clientSideLoad {
  /* background: #e43864;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: 72px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 0px 8px;
  line-height: 19px !important;
  border-radius: 10px;
  cursor: pointer; */


  background: #e43864;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  padding: 0px 8px;
  line-height: 19px !important;
  border-radius: 10px;
  cursor: pointer;
  width: 70px;




}

.clientSideEnd{
  background: #e43864;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  padding: 0px 8px;
  line-height: 19px !important;
  border-radius: 10px;
  cursor: pointer;
  width: 70px;
}

.agentSideLoad {
  /* background: #0c4fa5 !important;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: 127px;
  left: 34%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 0px 8px;
  line-height: 19px !important;
  border-radius: 10px;
  cursor: pointer; */



  background: #125ab3 !important;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: 10px;
  width: 68px;
  left: 19rem;
  transform: translate(-50%, -50%);
  position: relative !important;
  padding: 0px 8px;
  line-height: 19px !important;
  border-radius: 10px;
  cursor: pointer;
}

.forImg {
  background: unset !important;
}

.imgPreview1 {
  /* top: 212px;
  background: #ccc;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  min-width: 290px;
  height: 285px;
  z-index: 99999;
  border-radius: 10px; */
    top: 243px;
    background:white !important;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    min-width: 232px;
    height: 178px;
    z-index: 99999;
    border-radius: 10px;
}

.imgPreview1 img {
  max-width: 265px;
  margin: 8px 0 0px 0;
  height: 100px;
}

.imgPreview1 .head {
  margin: 6px 0 0 0;
  color: #000;
}

.imgPreview1 .actBtn button {
  background: #e33662;
  border: none;
  color: #fff;
  font-size: 13px;
  padding: 2px 7px;
}

/* .react-input-emoji--container{
  max-width: 100% !important;
  max-height: 10rem !important;
} */

/* .actBtn {
  margin: 7px 5px;
} */
.react-input-emoji--input {
  outline: none !important;
  max-height: 37px !important;
  
  /* z-index: 999 !important; */
}
.chatbox-popup__footer .react-input-emoji--input {
  outline: none !important;
  height: 30px;
  padding: 4px 10px 4px 10px !important;
}
.chatbox-popup__footer .react-input-emoji--container {
  max-width: 200px !important;
  height: 35px;
  margin: 0 !important;
}

.chatbox-popup__footer .react-emoji {
  margin: unset !important;
}

.picker-container {
  position: relative;
  width: 300px;
}

.emoji-icon {
  cursor: pointer;
  position: absolute;
  right: 65px;
  margin: 4px 0 0 0px;
  z-index: 99999;
}

aside.EmojiPickerReact.epr-main {
  /* height: 404px !important; */
  height: 305px !important;
  position: absolute !important;
  top: -33px !important;
  max-width: 300px !important;
  margin-left: -0px;
}

.EmojiPickerReact .epr-body::-webkit-scrollbar{
  display: none;
}
.chatbox-popup__footer .react-emoji-picker--wrapper {
  left: -50px !important;
}

.chatbox-popup__footer .react-emoji-picker {
  left: 0 !important;
}


.chatbox-popup1 {
  /* display: flex;
  position: fixed;
  z-index: 9999;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
  bottom: calc(2 * 15px + 52px);
  right: 15px;
  width: 345px;
  max-height: 85vh;
  background-color: #fff;
  border-radius: 15px; */
 
  display: flex;
  position: fixed;
  z-index: 9999;
  /* box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2); */
  flex-direction: column;
  /* bottom: calc(2 * 15px + 52px); */
  right: 0;
  left: 0;
  width: 100%;
  /* height: 82%; */
  max-width: 100%;
  max-height: 100%;
  /* background-color: #fff; */
  border-radius: 15px;
  overflow: scroll;
}
 
 
.chatbox-popup1 .chatbox-popup__main1 {
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * 15px) 15px;
  line-height: calc(15px + 15px / 2);
  color: #888;
  text-align: center;
  height: 107vh;
  /* overflow: hidden;
  overflow-y: scroll; */
  padding: 0 !important; 
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: white;
 
}


.chatbox-popup1 .chatbox-popup__header {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  color: #fff;
  background: #e12454;
  background: linear-gradient(to bottom right, #e12454, #e12454db);
  align-items: center;
  justify-content: space-around;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.chatbox-popup1 .chatbox-popup__header .chatbox-popup__avatar {
  background-color: #e12454;
  border: 5px solid rgba(0, 0, 0, 0.1019607843);
  border-radius: 50%;
}
.chatbox-popup1 .chatbox-popup__header .head-logo {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.1019607843);
  background: white;
}