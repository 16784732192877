.bot-chat{
    padding-top: 15px !important;
  
}
.user-chat a{
    color: blue !important;
}

.bot-chat a{
    color: blue !important;
}

.chatbox-popup {
    background-color: #fff;
    border-radius: 15px;
    bottom: 82px;
    /* box-shadow: 5px 5px 25px 0 rgba(46,61,73,.2); */
    display: flex;
    flex-direction: column;
    max-height: 85vh;
    position: fixed;
    right: 15px;
    width: 345px;
    z-index: 9999;
  }

  .newdesign{
    width: 24rem;
    height: 26rem;
  }

  .customer-offline-message{
    text-align: center;
    width: 100%;
    top: 15%;
    padding: 6px;
    font-size: 10px;
    font-family: 'poppins';
    background-color: #D3D0E3;
    animation: fadeInOut 6s ease infinite;
    z-index: 9999;
  }

  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .customer-online-message{
    text-align: center;
    width: 100%;
    top: 15%;
    padding: 6px;
    font-size: 10px;
    font-family: 'poppins';
    background-color: #EEFFDA;
    z-index: 9999;
  }
