

*, *::before, *::after {
  box-sizing: border-box;
}
.pagination ::ng-deep {
  font-size: 12px;
  margin-bottom: 0;
  height: 32px;
}
.settings_main .cust_hed p {
  font-weight: 700;
  font-size: 17px;
}
.settings_main .cust_hed small {
  font-size: 15px;
  color: #939393;
}
.settings_main .cust_hed .recent_cont span {
  font-size: 30px;
  color: #1473e6;
}
.settings_main .cust_hed .recent_cont p {
  padding-top: 4px;
  color: #242424;
  font-size: 18px;
  font-weight: 500;
}
.sub-master {
  padding: 10px;
  min-height: 100px;
  width: 100% !important;
}
.sub-master .icon-master span {
  font-size: 35px;
  color: #1473e6;
}
.sub-master .name-master {
  margin-top: 6px;
  color: #242424;
  font-size: 15px;
  font-weight: 500;
}
.sub-master .name-master p {
  margin-bottom: 0.1rem;
}
.sub-master .name-master .small {
  font-size: 13px;
  color: #626263;
  font-weight: 400;
}
.sub-master:hover {
  cursor: pointer;
  border-radius: 0;
  background: #ffff;
  align-items: center;
  box-shadow: 0 1.875em 20px -30px rgba(57, 48, 40, 0.68);
  transform: translateY(-5px);
  transition: all 0.5s;
}
.settings_main .cust_content {
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.nab-lg {
  display: block;
}
.mobile-hed-top {
  margin-right: 30px;
}
.navbar-mobile {
  display: none;
}
.mobile-right {
  margin-right: 24px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 640px) and (orientation: portrait) {
  .navbar-mobile {
    display: block;
  }
  .navbar-mobile .material-symbols-outlined {
    font-size: 14px;
  }
  .min-33h {
    min-height: 26px;
  }
  .nab-lg {
    display: none;
  }
  .sidebar_filter {
    width: 86%;
    height: 91vh;
    top: 59px;
  }
  .filter {
    height: 92vh;
  }
  .mobile-right {
    margin-right: 24px;
  }
  .hed_left .mob-hed {
    font-size: 10px;
  }
  .header_role {
    padding: 7px 13px 7px 51px;
  }
  .mob-tick {
    margin-left: 23px;
  }
  .mob-tick .menu-contents-right {
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar-mobile {
    display: block;
  }
  .nab-lg {
    display: none;
  }
  .mobile-right {
    margin-right: 24px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mobile-right {
    margin-right: 24px;
  }
}
@media (max-width: 992px) {
  .navbar-mobile {
    display: block;
  }
  .nab-lg {
    display: none;
  }
  .hed_left .mob-hed {
    font-size: 10px;
  }
}
@media (min-width: 1400px) {
  .cust_content {
    height: 65vh;
  }
}