*,
*::before,
*::after {
  box-sizing: border-box;
}

// ------------------Bubble chat color ------------------
$white: #ffff;
$offwhite: #f5f7f9;
$secondary_off: #1473e60a;
$secondaryblue: #006fff;
$darklightblack: #2e384d;
$darklight: #242424;
$black: #000;
$boxshadowblack: #00000029;
$black_light: #c9c9c9;
$black_light_light: #939393;
$lightblack: #626263;
$dbbg: #f7f7f7;
$blueshade: #b2c3e8;
$light_green: #1473e60d;
$base-height: 345px;
$base-dimension: 15px;
$base-border-radius: 10px;
$base-button-size: 52px;
$primary-color: #0b3363;
$Primary_2: #1473e6;
$accent-color: #f6f7f9;
$background-color: #fff;
$font-color: #888;
$blueshade: #b2c3e8;
// ------------------Bubble chat color end ------------------

a {
  text-decoration: none;
}
.form-select:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

// ------------------Bubble chat style start ------------------

@mixin chabox-container {
  display: flex;
  position: absolute;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
}

@mixin chatbox-header {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: $base-dimension;
  color: $background-color;

  background: #0b3363;
  background: -webkit-linear-gradient(top left, #0b3363, #4883aa);
  background: -moz-linear-gradient(top left, #0b3363, #4883aa);
  background: linear-gradient(to bottom right, #0b3363, #4883aa);

  align-items: center;
  justify-content: space-around;
}

@mixin chatbox-main {
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * #{$base-dimension}) #{$base-dimension};
  line-height: calc(#{$base-dimension} + #{$base-dimension} / 2);
  color: $font-color;
  text-align: center;
  max-height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin chatbox-footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: $base-dimension;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: space-around;
}

@mixin chatbox-floating-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: $base-button-size;
  height: $base-button-size;
  color: $background-color;
  background: #0b3363;
  background: -webkit-linear-gradient(top left, #0b3363, #4883aa);
  background: -moz-linear-gradient(top left, #0b3363, #4883aa);
  background: linear-gradient(to bottom right, #0b3363, #4883aa);
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-style: normal;
  background-color: $accent-color;
}

h1 {
  margin: 0;
  font-size: $base-dimension;
  line-height: 1;
}
.btn-outline-primary1 {
  color: $white;
  &:hover {
    color: $white;
    background-color: $Primary_2;
  }
}
.btn-primary1 {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover {
    color: #fff;
    background-color: $Primary_2;
    border-color: $Primary_2;
  }
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    $primary-color 0%,
    #26a0da 51%,
    #314755 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

button {
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  cursor: pointer;

  &.chatbox-open {
    @include chatbox-floating-button;
    margin: $base-dimension;
    z-index: 99999;
  }

  &.chatbox-close {
    @include chatbox-floating-button;
    display: none;
    z-index: 99999;
    margin: $base-dimension calc(2 * #{$base-dimension} + #{$base-button-size})
      $base-dimension $base-dimension;
  }
}

textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: calc(#{$base-dimension} + #{$base-dimension} / 2);
  padding: 0 calc(#{$base-dimension} / 2);
  font-size: 13px;
  line-height: calc(#{$base-dimension} + #{$base-dimension} / 2);
  color: $font-color;
  background-color: none;
  border: 0;
  outline: 0 !important;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: $font-color;
  }
}
.chat-first-form {
  input,
  .form-select {
    height: 35px;
  }
  .form-select,
  label {
    font-size: 14px;
  }
}

.chatbox-popup {
  @include chabox-container;
  display: none;
  bottom: calc(2 * #{$base-dimension} + #{$base-button-size});
  right: $base-dimension;
  width: $base-height;
  max-height: 85vh;
  background-color: $background-color;
  border-radius: $base-dimension;

  .chatbox-popup__header {
    @include chatbox-header;
    border-top-right-radius: $base-border-radius;
    border-top-left-radius: $base-border-radius;

    .chatbox-popup__avatar {
      // margin-top: -32px;

      background-color: $primary-color;
      border: 5px solid #0000001a;
      border-radius: 50%;
    }
    .head-logo {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 3px solid #0000001a;
      background: $white;
      img {
        width: 40px;
      }
    }
  }

  .chatbox-popup__main {
    @include chatbox-main;
    padding: 0 !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background: $white;
  }

  .chatbox-popup__footer {
    @include chatbox-footer;
    border-bottom-right-radius: $base-border-radius;
    border-bottom-left-radius: $base-border-radius;
  }
}

.chatbox-panel {
  @include chabox-container;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  width: $base-height;
  background-color: $background-color;

  .chatbox-panel__header {
    @include chatbox-header;
    flex: 0 0 auto;
    .head-logo {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 3px solid #0000001a;
      background: $white;
      img {
        width: 40px;
      }
    }
  }

  .chatbox-panel__main {
    @include chatbox-main;
    flex: 1 1 auto;
    padding: calc(2 * 8px) 15px;
    max-height: 88vh;
    .chat-box-body {
      .chat-logs {
        .messages {
          height: 74vh !important;
          max-height: 74vh;
        }
      }
    }
    .chatbox-popup__footer {
      @include chatbox-footer;
      border-bottom-right-radius: $base-border-radius;
      border-bottom-left-radius: $base-border-radius;
    }
  }

  .chatbox-panel__footer {
    @include chatbox-footer;
    flex: 0 0 auto;
  }
}
.chat-box-overlay {
  background: $blueshade;
  display: flex;
  align-items: center;
  padding: 10px;
  .img_box {
    width: 11%;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 5px solid #fff;
      -webkit-box-shadow: 0 0 1px 1px rgb(0 0 0 / 30%);
      -moz-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 1px 1px rgb(0 0 0 / 30%);
      background: #fff;
    }
  }
  .info-box {
    width: 60%;
    color: #ffff;
    h3 {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 2px;
    }
    .profile-status {
      color: #fff;
      font-size: 11px;
      span {
        float: left;
        width: 10px;
        height: 10px;
        background: #04e204;
        display: block;
        border: 1px solid #106500;
        border-radius: 5px;
        margin: 3px 3px 0 0;
      }
    }
  }
}

// chat log start
.chat-box-body {
  background: $white;
  .chat-logs {
    flex-shrink: 2;
    overflow-y: auto;
    .messages {
      background: #f7f7f7;
      padding: 5px;
      overflow: hidden;
      max-height: 55vh;
      overflow-y: auto;
    }
    .time {
      font-size: 10px;
      background: #eee;
      padding: 2px 14px;
      border-radius: 7rem;
      color: $primary-color;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto;
    }
    .user-chat-main {
      margin: 8px 0;
      .user-chat {
        padding: 10px 20px;
        border-radius: 1.125rem 1.125rem 0 1.125rem;
        /* Created with https://www.css-gradient.com */
        background: #79c8e7;
        background: -webkit-linear-gradient(top left, #79c8e7, #4a80bd);
        background: -moz-linear-gradient(top left, #79c8e7, #4a80bd);
        background: linear-gradient(to bottom right, #79c8e7, #4a80bd);
        color: white;
        font-size: 13px;
        max-width: 90%;
        text-align: left;
      }
    }
    .bot-chat-main {
      margin: 8px 0;
      .bot-chat {
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        background: $white;
        border-radius: 1.125rem 1.125rem 1.125rem 0;
        min-height: 2.25rem;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 13px;
        max-width: 90%;
        text-align: left;
      }
    }
  }
}

.typing {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0rem;
  box-sizing: border-box;
  background: $primary-color;
  border-radius: 50%;

  &.typing-1 {
    animation: typing 3s infinite;
  }
  &.typing-2 {
    animation: typing 3s 250ms infinite;
  }
  &.typing-3 {
    animation: typing 3s 500ms infinite;
  }
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }

  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}
.chat-message__avatar-frame {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
// ------------------Bubble chat style end ------------------
// scrollbar

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: $blueshade;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: $Primary_2;
  border: 1px solid $Primary_2;
  &:hover {
    background: $primary-color;
    border-color: $primary-color;
  }
}

// scrollbar ends
//////////////////////////###########################//////////////
///
/////////////////#################/////////////////////////////////
// media quary
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
