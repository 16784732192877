.custom-scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

#mail:hover img {
  filter: brightness(0) invert(1);
}

.custom-scroll::-webkit-scrollbar {
  width: 0.5rem;
  display: none;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.closeMenucard {
  position: absolute;
  bottom: 55px;
  right: 90%;
}
.closeMenucard2 {
  position: absolute;
  bottom: 55px;
  right: 90%;
}
.closeMenucard3 {
  position: absolute;
  bottom: 55px;
  right: 90%;
}
.menucard {
  height: 54px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  right: 45vw;
  top: 90px;
  padding: 12px;
  padding-left: 17px;
  z-index: 1;
}
.menucard3 {
  /* width: 120px; */
  width: 145px;

  height: 54px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  right: 45vw;
  top: 15vh;
  padding: 12px;
  padding-left: 17px;
  z-index: 99;
}

.menucard2 {
  width: 200px;
  height: 54px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  right: 45vw;
  top: 90px;
  padding: 12px;
  padding-left: 17px;
  z-index: 1;
}

@media (min-width: 1422px) {
  .menucard {
    top: 14.5vh;
  }
}
@media (min-width: 1600px) {
  .menucard {
    top: 13vh;
  }
}

@media (min-width: 1900px) {
  .menucard {
    top: 14vh;
  }
}

.couple-email::-webkit-scrollbar {
  display: none;
}
.menucard button {
  margin-right: 5px;
}
.menucard2 button {
  margin-right: 5px;
}
.menucard3 button {
  margin-right: 5px;
}

.EmojiPickerReact .epr-preview {
  display: none !important;
}
.epr-header-overlay {
  display: none !important;
}

.audio-recorder {
  background: none !important;
  box-shadow: none !important;
}

.recording {
  position: absolute !important;
  top: 504px !important;
  right: 49%;
  border-radius: 14px !important;
  width: 240px !important;
  transition: all 0.1s ease-in-out !important;
  background: #fff !important;
  box-shadow: 1px 1px 2px solid black;
}

.audio-background {
  background-color: yellow !important;
}

.audio-file {
  top: 100px;
  position: absolute;
  width: 100px;
  height: 50px;
}

.audio-preview {
  border-radius: 50%;
  width: 300vw;
  top: 100px;
  position: absolute;
}

.audio-element {
  height: 40px !important;
  width: 275px;
  margin-bottom: 10px;
}

.deleteiconOnHover:hover {
  color: red;
}

.sendiconOnHover:hover {
  color: rgba(0, 194, 237, 0.861);
}

.audio-file {
  top: 100px;
  position: absolute;
  width: 100px;
  height: 50px;
}

.audio-preview {
  border-radius: 50%;
  width: 300vw;
  top: 100px;
  position: absolute;
}

.audio-element {
  height: 40px !important;
  width: 279px !important;
  margin-bottom: -1px !important;
  margin-left: 14px !important;
}

.deleteiconOnHover:hover {
  color: red;
}

.sendiconOnHover:hover {
  color: rgba(0, 194, 237, 0.861);
}

.canned_div {
  bottom: 23.3rem;
  right: 10px;
  height: 19rem;
  width: 17rem;
  border-radius: 10px;
  box-shadow: 2px 2px 6px gray;
}

.preview_audio {
  position: static !important;
}

.msg_audio {
  height: 40px;
  width: 180px;
}

.chat-logs::-webkit-scrollbar {
  display: none !important;
}

.audio-recorder-mic {
  padding-left: 19px !important;
}
.canned_card::-webkit-scrollbar {
  display: none;
}

.canned_message {
  font-size: 18px !important;
}

.tag_div {
  width: 100%;
  padding: 3px;
  display: flex;
  background: rgba(228, 228, 228, 0.95);
  outline: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
}

.close-add-Topic {
  cursor: pointer;
}

.pluscircleCursor {
  cursor: pointer;
}
