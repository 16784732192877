*,
*::before,
*::after {
  box-sizing: border-box;
}

$white: #ffff;
$offwhite: #f5f7f9;
$Primary_1: #0b3363;
$Primary_2: #1473e6;
$secondary_off: #1473e60a;
$secondaryblue: #006fff;
$darklightblack: #2e384d;
$darklight: #242424;
$black: #000;
$boxshadowblack: #00000029;
$black_light: #c9c9c9;
$black_light_light: #939393;
$lightblack: #626263;
$red: #ed1c24;
$red1: #eb0f00;
$green: #088a08;
$orange: #f64701;
$yellow: #ffb800;
$dbbg: #f7f7f7;
$blueshade: #b2c3e8;
$light_green: #f9faff;

// ################################################################################
:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: $Primary_2;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;
  --body-font: "Poppins", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;

  a {
    text-decoration: none;
  }
}

*,
::before,
::after {
  box-sizing: border-box;
}
// body{
//     overflow: hidden;
//     height: 100vh;
// }
// height
.vh-46 {
  height: 46vh !important;
}

.vh-51 {
  height: 51vh !important;
}

.vhm-52 {
  max-height: 52vh !important;
}
.vhm-53 {
  max-height: 53vh !important;
}
.vhm-54 {
  max-height: 54vh !important;
}
.vh-55 {
  height: 55vh !important;
}
.vh-60 {
  height: 60vh !important;
}

.vhm-61 {
  max-height: 61vh !important;
}

.vh-62 {
  max-height: 62vh !important;
}
.vh-63 {
  max-height: 63vh !important;
}
.vh-64 {
  height: 64dvh !important;
}
.vh-65 {
  height: 65dvh !important;
}
.vh-66 {
  height: 66dvh !important;
}

.vh-68 {
  height: 68dvh !important;
}

.vhm-65 {
  max-height: 65dvh !important;
}

.vhm-68 {
  max-height: 68dvh !important;
}

.vh-73 {
  height: 73dvh !important;
}

.vh-74 {
  height: 74dvh !important;
}

.vh-75 {
  height: 75dvh !important;
}

.vh-76 {
  height: 76dvh !important;
}

.vh-77 {
  height: 77dvh !important;
}

.vh-78 {
  height: 78dvh !important;
}

.vh-79 {
  height: 79dvh !important;
}

.vh-85 {
  height: 85dvh !important;
}
//  height end

/* table fixed start  */
.fixed-table-card {
  border: unset !important;
  border-radius: 0 !important;
  overflow: hidden;
  
}
.fixed-table-card ::-webkit-scrollbar {
  display: none;
}




.table-fixeded {
  // min-width: max-content;
  // border-collapse: separate;

  thead {
    th {
      position: sticky !important;
      top: 0;
      background: white;
      // z-index: 99;
      font-size: 13px;
      vertical-align: top !important;
      color: $Primary_1;
      // z-index: 999;
    }
  }

  tbody {
    font-size: 13px;
  }

  button:not(:disabled),
  [type="button"]:not(:disabled) {
    border: none;
  }

  p {
    font-size: 13px;
  }

  .btn:active,
  .btn:focus {
    outline: 0;
    box-shadow: none;
    border: none;
  }
}
/* table fixed start end */
