.icons-size {
  height: 30px !important;
  width: auto !important;
}
.multimediabody {
  height: 81dvh;
  overflow: hidden;
}
.multimediabody::-webkit-scrollbar {
  display: none;
}
.multimediatable{
    height: 90dvh;
    overflow: hidden;
    margin-left: 45px;
}
.multimediacard{
    width:205vh;
}
