ul {
  list-style: none;
}

.agentStatus{
  margin-left: 47px;
}

.agentStatus-dropdown {
  position: relative;
  font-size: 12px;
}

.agentStatus-btn-dropdown {
  width: 160px;
  border: none;
  padding: 3px;
  border-radius: 5px;
}

.agentStatus-dropdown__item {
  padding: 5px 10px;
  cursor: pointer;
}

.Status_dropdown__list {
  position: absolute;
  top: 3px;
  left: 0px;
  background-color: #fff;
  padding-left: 0px;
  width: 162px;
  z-index: 9999;
  border: 1px solid lightgrey;
  font-size: small;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.Status_submenu__item {
  padding: 5px 10px;
  cursor: pointer;
}

.Status_submenu__item:hover {
  color: rgb(19, 19, 19);
}

.Status_submenu {
  position: relative;
}

.Status_submenu:hover .Status_submenu__list {
  display: block;
}

.Status_submenu__list {
  display: none;
  position: absolute;
  padding-left: 0px;
  left: 0px;
  top: 30px;
  background-color: #fff;
  border: 1px solid lightgrey;
  width: 162px;
  overflow-y: scroll;
  font-size: small;
  z-index: -9999;
}

.Status_submenu__list::-webkit-scrollbar {
  display: none !important;
}

expandable-table {
  width: 100%;
  border-collapse: collapse;  
  overflow: hidden;
}

 .status-update-table-body {
  max-height: 340px;
  overflow-y: auto;
  display: block;
}

.status-update-table-body::-webkit-scrollbar {
  display: none;
} 

