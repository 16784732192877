.service_uploadTable {
  overflow-x: scroll;
  width: 150%;
}

.service_uploadTable::-webkit-scrollbar {
  display: none;
}

.clearbutton {
  position: absolute;
  right: 20px;
  bottom: 95px;
}

.clearbuttonAgentReports {
  position: absolute;
  right: 20px;
  bottom: 50px;
}

.salesbutton {
  position: absolute;
  display: flex;
  justify-content: end;
  left: 163%;
}

.servicebutton {
  position: absolute;
  display: flex;
  justify-content: end;
  left: 165%;
}

.sessiontable {
  height: 90dvh;
  overflow: hidden;
  margin-left: 45px;
}
.supervisorContactbody {
  height: 81dvh;
  overflow: hidden;
}
.supervisorContactbody::-webkit-scrollbar {
  display: none;
}
.agenttable {
  margin-top: -10px;
  margin-left: -40px;
}
.session2table {
  height: 81dvh;
  overflow: hidden;
}
.session2table::-webkit-scrollbar {
  display: none;
}
.sessioncardbody {
  margin-left: -30px;
}

.agentContactbody{
  height: 81dvh;
  overflow: hidden;
}
.agentContactbody::-webkit-scrollbar {
  display: none;
}