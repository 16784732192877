*,
*::before,
*::after {
  box-sizing: border-box;
}

.chat-search input {
  background: #f9fafc 0% 0% no-repeat padding-box;
  border: 1px solid #dbe5ed;
}

.form-control::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}

.list-of-incoming-request {
  position: fixed;
  top: 58px;
  right: 25px;
  width: 290px;
  height: 90vh;
  /* background: #0066ff; */
  z-index: 99999;
  overflow: scroll;
}

.list-of-incoming-request::-webkit-scrollbar {
  display: none;
}

.incomecall_details {
  display: block;
  position: fixed;
  right: 1px;
  z-index: 999;
  top: 58px;
  height: 535px;
  overflow-y: scroll;
}

.incomecall_details::-webkit-scrollbar {
  display: none;
}

#incomming-user-card {
  margin: 10px;
  width: 290px;
  height: auto;
  /* height: 170px; */
  /* height: 210px; */
  background: #012a67;
  color: white;
  border-radius: 4px;
  text-align: center;
  font-size: 35px;
  cursor: pointer;
  z-index: 99999;
}

.image-paragraph {
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
  padding-bottom: 9px;
}
.image-paragraph p {
  color: white;
  font-family: sans-serif;
  font-size: 12.5px;
  padding-left: 10px;
  font-weight: 600;
}

.image-paragraph img {
  margin-right: 10px;
  width: 20px;
}

.list-details p {
  color: #ffff;
  font-size: 11px;
  font-weight: 600;
}

.income-call-icon {
  border-radius: 50%;
  background-color: white;
}

.call-animation {
  background: #fdfdfd;
  color: aliceblue;
  box-shadow: rgb(0 0 0 / 0%) 0px 1px 3px, rgb(0 0 0 / 14%) 0px 1px 2px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  animation: call 1.5s ease infinite;
}
.photo {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 8px 6px -3px inset;
}

.chat_div::-webkit-scrollbar {
  display: none;
}

.chat-users {
  padding: 5px;
  height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  cursor: pointer;
}
.chat-users::-webkit-scrollbar {
  display: none;
}

.chat-contact {
  border-radius: 0px !important;
}

.chat-users .chat-contact .photo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.chat-users .chat-contact .chat-details p {
  font: normal normal normal 12px Poppins;
  max-width: 160px;
  color: rgba(148, 146, 146, 0.7647058824);
}
.chat-users .chat-contact .chat-time {
  font: normal normal normal 11px Poppins;
  letter-spacing: 0px;
  color: rgba(148, 146, 146, 0.7647058824);
}

.chat-users2 {
  height: 80vh;
  width: 23vw;
  overflow: hidden;
  overflow-y: auto;
  cursor: pointer;
}
.chat-users2::-webkit-scrollbar {
  display: none;
}

.chat-users2 .chat-contact .photo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.chat-users2 .chat-contact .chat-details p {
  font: normal normal normal 12px Poppins;
  max-width: 160px;
  color: rgba(148, 146, 146, 0.7647058824);
}
.chat-users2 .chat-contact .chat-time {
  font: normal normal normal 11px Poppins;
  letter-spacing: 0px;
  color: rgba(148, 146, 146, 0.7647058824);
}

.name {
  font-size: 12px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  max-width: 200px;
}

.in-call {
  font-size: 10px !important;
  padding: 1px !important;
}

.online {
  width: 8px;
  height: 8px;
  background-color: #84c857;
  border-radius: 50%;
}
.offline {
  width: 8px;
  height: 8px;
  background-color: #ff8c00;
  border-radius: 50%;
}

.discont {
  width: 8px;
  height: 8px;
  background-color: #eb2f32;
  border-radius: 50%;
}

.chat-social {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: white;
  font-size: 9px;
}

.online2 {
  position: absolute;
  top: 29px;
  right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  color: white;
  font-size: 9px;
}

.online2-bg {
  background: #84c857;
}

.what-bg {
  background: #11b442;
}

.inst-bg {
  background: #fd0b56;
}

.fb-bg {
  background: #1876f1;
}

.twit-bg {
  background: #4092cc;
}

.call-bg {
  background: #056422;
}

.badge-bg {
  background: #d4e1f2 0% 0% no-repeat padding-box;
}

.chat-active {
  background: #1473e6;
  box-shadow: inset 5px 5px 6px #245793, inset -5px -5px 6px #2da3ff;
  border-radius: 5px !important;
}
.chat-active .name {
  color: white !important;
}
.chat-active p {
  color: rgba(218, 214, 214, 0.7607843137) !important;
}

.dvh-89 {
  height: 89dvh;
}

.chat-user-main p {
  font: normal normal normal 12px Poppins;
  max-width: 160px;
  color: rgba(148, 146, 146, 0.7647058824);
}

.chat-boxs {
  background: #f9fafc !important;
  height: 77vh;
}
.chat-box-body {
  background: #f9fafc !important;
}

.chat-box-body .chat-logs {
  flex-shrink: 2;
  overflow-y: auto;
}
.chat-box-body .chat-logs .messagess {
  overflow: hidden;
  height: 64vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.chat-box-body .chat-logs .messagess::-webkit-scrollbar {
  display: none;
}
.chat-box-body .chat-logs .times {
  font-size: 10px;
  background: #000000dd;
  padding: 2px 14px;
  border-radius: 7rem;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto;
}

.user-chats-file {
  padding: 10px 0px;
  border-radius: 10px 10px 0 10px;
  background: #217346;
  color: #fff;
  font-size: 13px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 90%;
  text-align: left;
  margin-bottom: 5px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: scroll;
}

.user-chats-file a {
  color: #fff;
  display: block;
  padding: 5px;
  background: #217346;
  border-radius: 0 0 10px 10px;
  transition: 0.2s;
  flex-direction: column;
}

.user-chats {
  padding: 10px 20px;
  border-radius: 10px 10px 0 10px;
  background: rgba(0 45 100 / 99%);
  color: #fff;
  font-size: 13px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 90%;
  text-align: left;
  margin-bottom: 5px;
}

.user-chats-audio {
  padding: 15px 0px 16px 16px !important;
  border-radius: 10px 10px 0 10px;
  background: rgba(0 45 100 / 99%);
  color: #fff;
  font-size: 27px;
  width: -moz-fit-content;
  width: fit-content;
  /* width: 28%; */
  max-width: 90%;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 25px;
}

.chat-box-body .chat-logs .user-chat-main .user-chats-audio:nth-child(even) {
  border-radius: 10px 0 10px 10px;
}
.chat-box-body .chat-logs .bot-chat-main .bot-chats {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: #748191;
  color: white;
  border-radius: 10px 10px 10px 0px;
  min-height: 2.25rem;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
  max-width: 90%;
  text-align: left;
  margin-bottom: 5px;
}
.chat-box-body .chat-logs .bot-chat-main .bot-chats:nth-child(even) {
  border-radius: 0px 10px 10px 10px;
}

.chat-time-r {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  color: #afbbc6;
  margin: 5px 0;
}

.chat-message__avatar-frames {
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  border: 2px solid #1473e6;
}

.chat-time-l {
  display: flex;
  justify-content: flex-start;
  font-size: 10px;
  color: #afbbc6;
  margin: 5px 0 5px 0px;
}

.typing-main {
  width: 40px;
}
.typing-main .typing {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0rem;
  box-sizing: border-box;
  background: white;
  border-radius: 50%;
}
.typing-main .typing.typing-1 {
  animation: typing 3s infinite;
}
.typing-main .typing.typing-2 {
  animation: typing 3s 250ms infinite;
}
.typing-main .typing.typing-3 {
  animation: typing 3s 500ms infinite;
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}
.chatbox-popup__footer {
  box-sizing: border-box;
  display: flex;
  border-radius: 60px;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #dbe5ed;
  align-items: center;
  justify-content: space-around;
  background: white;
}
.chatbox-popup__footer .chat-textarea {
  flex: 9;
}
.chatbox-popup__footer .chat-textarea textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 40px;
  padding: 11px;
  font-size: 13px;
  color: rgba(11, 51, 99, 0.7490196078);
  background-color: none;
  border: 0;
  outline: 0 !important;
  resize: none;
  /* overflow: hidden; */
  overflow: auto;
}
.chatbox-popup__footer .uplode {
  flex: 1;
  text-align: center;
  font-size: 16px;
}
.chatbox-popup__footer .uplode .attch-label {
  color: rgba(11, 51, 99, 0.7490196078);
}

.emoji-btn:hover {
  background-color: #1473e6;
  border-radius: 100px;
}

.chatbox-popup__footer .uplode input {
  display: none;
  visibility: none;
  color: rgba(11, 51, 99, 0.7490196078);
}
.chatbox-popup__footer .smiley {
  font-size: 16px;
  color: rgba(11, 51, 99, 0.7490196078);
  cursor: pointer;
}

.btn-chatsent {
  width: 40px;
  height: 40px;
  color: white;
  background: #4273b6;
  cursor: pointer;
  border-radius: 100px;
  font-size: 15px;
}
.btn-chatsent:focus {
  border-color: #7ccee9;
  outline: 0;
  border-radius: 100px;
  box-shadow: none;
}
.btn-chatsent:hover {
  color: white;
  border-radius: 100px;
  background: #1473e6;
}

.details-user-chat .photo {
  width: 100px;
  height: 100px;
}
.details-user-chat .use-cont p,
.details-user-chat .use-cont i {
  font-size: 13px;
  color: #999999;
  margin-bottom: 0;
}
.details-user-chat .use-cont i {
  margin-right: 5px;
}
.details-user-chat .use-cont p {
  margin-top: 4px;
}

.chat-tabs .nav-pills .nav-link {
  padding: 5px 10px;
  font-size: 13px;
}
.chat-tabs .nav-pills .nav-link .active {
  color: white;
  background-color: #0b3363 !important;
}

.btn-primary-3 {
  border: 1px solid #4273b6;
  border-radius: 0;
  font-size: 12px;
  color: #4273b6;
  background: white;
}
.btn-primary-3 span {
  font-size: 17px;
}
.btn-primary-3:focus {
  border-color: #7ccee9;
  outline: 0;
  box-shadow: none;
}
.btn-primary-3:hover {
  color: white;
  background: #4273b6;
}

.btn-primary-4 {
  color: white;
  border: 1px solid #4273b6;
  font-size: 12px;
  border-radius: 0;
  background: #4273b6;
}
.btn-primary-4 span {
  font-size: 17px;
}
.btn-primary-4:focus {
  border-color: #7ccee9;
  outline: 0;
  box-shadow: none;
}
.btn-primary-4:hover {
  color: #4273b6;
  background: white;
}

.card-bg {
  background: #f7f6f3;
}

.card-chat {
  background: #d4e1f2 0% 0% no-repeat padding-box;
  border-radius: 6px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.box {
  max-width: 30px !important;
  height: 30px !important;
  border-radius: 4px !important;
  color: white;
  font-size: 15px;
}

.box2 {
  border-radius: 6px;
  padding: 2px 11px;
  font-size: 11px;
  margin-bottom: 5px;
  box-shadow: 3px 4px 3px #99a2ae, -4px -6px 8px #ffffff80;
}
.box2 strong {
  color: #1975d0;
}

.sms-bg {
  background: #0b3363;
}

.historynew {
  height: 45vh;
  overflow: hidden;
  overflow-y: auto;
}

.tickt-name {
  font-size: 15px;
  color: #131313;
  font-weight: bold;
}

.file-main {
  height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}

.file-main::-webkit-scrollbar {
  display: none;
}

.details-user-all {
  height: 25vh;
  overflow: hidden;
  overflow-y: auto;
}
.details-user-all::-webkit-scrollbar {
  display: none;
}

.time-line-main {
  height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}

.time-line-main::-webkit-scrollbar {
  display: none;
}

.time-line-main .timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.time-line-main .timeline .timeline-item {
  padding-left: 40px;
  position: relative;
}
.time-line-main .timeline .timeline-item .timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}
.time-line-main .timeline .timeline-item .timeline-marker:before {
  content: "\f00c";
  font-family: "FontAwesome";
  background: #cef9c6;
  border: 3px solid transparent;
  border-radius: 50%;
  display: block;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 4px;
  left: 0;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.time-line-main .timeline .timeline-item .timeline-marker:after {
  content: "";
  width: 3px;
  background: #ccd5db;
  display: block;
  position: absolute;
  top: 47px;
  bottom: 0;
  left: 18px;
}
.time-line-main .timeline .timeline-item .timeline-content {
  padding: 15px 5px 10px 14px;
  color: #707070;
  font-size: 13px;
}
.time-line-main .timeline .timeline-item .timeline-content strong {
  color: #0b3363;
  font-size: 13px;
}

.vh69 {
  height: 69vh !important;
}

@media (min-width: 1400px) {
  .chat-box {
    height: 81vh;
  }
  .historynew {
    height: 33vh;
  }

  .chat-box-body .chat-logs .messagess {
    height: 68vh;
  }
} /*# sourceMappingURL=chat.css.map */

.agentSideLoad {
  background: #0b3363 !important;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: 10px;
  width: 66px;
  left: 19rem;
  transform: translate(-50%, -50%);
  position: relative !important;
  padding: 0px 8px;
  line-height: 19px !important;
  border-radius: 10px;
  cursor: pointer;
}

.card2 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #dbedf9; */
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px 3px;
  height: 100%;
  margin-left: 5px;
}

.cardchat {
  display: flex;
  flex-direction: column;
  /* min-width: 0; */
  word-wrap: break-word;
  /* background-color: #362b2b; */
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px 3px;
  height: 100%;
  width: 85vh !important;
  margin-left: 5px;
  /* box-shadow: 1px 1px 5px black; */
}

.cardchat2 {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px 3px;
  /* margin-left: 5px; */
  height: 100%;
  /* width: 156vh !important; */

  /* box-shadow: 1px 1px 5px black; */
}

.cardcontactlist {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f6f7f9;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px 3px;
  height: 529px;
  /* box-shadow: 1px 1px 2px ; */
}

/* .cardinternal{
   position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f6f7f9;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 3px 3px;
  height: 529px;
  box-shadow: 1px 1px 2px ;
}   */

.chat-profile {
  background-color: #f9fafc;
  border-radius: 3px 3px 0 0;
}

.slide-in-out {
  transition: transform 0.5s ease-in-out;
  transform: translateY(-100%);
}

.slide-in-out.active {
  transform: translateY(0);
}
