.background_color{
    background-color: #294e9f;
    color: white;
}


/* chat menu component */
.transferOkBtn{
    float: right;   
}

.transferOkDiv{
    padding: 10px;
}

.whatsappEdit-icon{
    position: absolute;
    right: 45px;
    bottom: 99px;
}




