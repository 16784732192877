.table-container {
  overflow-x: auto;
  overflow-y: auto;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 65vh;
}

.table-fixed thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 99;
  font-size: 13px;
  vertical-align: top !important;
  color: #0b3363;
}
.table-fixed tbody {
  font-size: 13px;
}
.table-fixed button:not(:disabled),
.table-fixed [type="button"]:not(:disabled) {
  border: none;
}
.table-fixed p {
  font-size: 13px;
}
.table-fixed .btn:active,
.table-fixed .btn:focus {
  outline: 0;
  box-shadow: none;
  border: none;
}

.fixed-table-card {
  border: unset !important;
  box-shadow: rgba(10, 10, 10, 0.1) 0px 0px 5px 0px;
  border-radius: 0 !important;
  overflow: hidden;
}

.vhm-61 {
  max-height: 61vh !important;
}

.vh-46 {
  height: 46vh !important;
}

.cust-main {
  overflow: hidden;
  overflow-y: auto;
  height: 51vh;
}

.pagination ::ng-deep {
  font-size: 12px;
  margin-bottom: 0;
  height: 32px;
}

.cont-search input {
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 6px -3px inset;
}
.cont-search .form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 6px -3px inset !important;
}

.nav-pills .nav-link {
  text-transform: uppercase;
  font-size: 12px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 6px -3px inset;
  color: #ffffff;
  background-color: #1473e6;
  border-color: #1473e6;
}

.new-add a {
  font-size: 13px;
}
.new-add a span {
  font-size: 19px;
}

.dashboard {
  height: 95dvh;
  overflow: hidden;
}

.ticket_round,
.r_round {
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 6px -3px inset;
}

.customer_filter {
  height: 70vh;
}

.btn-outline-select {
  color: #242424;
  border: 1px solid #c9c9c9;
  border-radius: 0;
}
.btn-outline-select:hover {
  color: white;
  background-color: #1473e6;
  border-color: #1473e6;
}

.d-flex-p {
  display: flex;
  align-content: center;
  align-items: center;
}

.fs-13 {
  font-size: 13px !important;
}

.cust_hed-new {
  height: 89vh;
}

.fixed-table-card {
  border: unset !important;
  box-shadow: rgba(10, 10, 10, 0.1) 0px 0px 5px 0px;
  border-radius: 0 !important;
  overflow: hidden;
}

.contacts-list {
  height: 67dvh;
  overflow: hidden;
  overflow-y: auto;
}
.contacts-list .contact {
  cursor: pointer;
  margin-bottom: 2px;
}
.contacts-list .contact:hover {
  background: rgba(11, 51, 99, 0.0784313725);
}
.contacts-list .contact:active {
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 6px -3px inset;
  background: #f6fafe;
}
.contacts-list .contact .cont-name {
  font-size: 13px;
}
.contacts-list .contact .cont-email {
  font-size: 12px;
}
.contacts-list .active {
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 6px -3px inset;
  background: #f6fafe;
}

.active-cont {
  background: rgba(11, 51, 99, 0.0784313725);
}

.contact-hed p {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
  font-size: 22px;
  padding-left: 13px;
}

.color_b {
  color: #1473e6 !important;
}

.cust_details_icon {
  font-size: 1rem;
}

.cust_icon {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b3363;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 0px;
}

.cont-details-hed {
  padding: 15px;
}
.cont-details-hed .ticket_round {
  width: 55px;
  height: 55px;
  font-size: 30px;
}
.cont-details-hed .cont-name {
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  font-size: 14px;
  font-size: 1.5rem;
}

.cont-detail-sub .tab-pane {
  background: #f6fafe;
  padding: 10px;
  height: 54vh;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 6px -3px inset;
  overflow-y: auto;
}

.btn-primary-1 {
  color: white;
  border: 1px solid #1473e6;
  border-radius: 0;
  background: #1473e6;
  font-family: "Noto Sans JP", sans-serif;
}
.btn-primary-1:focus {
  border-color: #0b3363;
  outline: 0;
  box-shadow: none;
}
.btn-primary-1:hover {
  color: #1473e6;
  background: white;
}

.active-cont {
  background: rgba(11, 51, 99, 0.0784313725) !important;
}

.cust_details_icon {
  font-size: 1rem;
}

.cust_details_new p {
  font-weight: 600;
  font-size: 12px;
}
.cust_details_new .small {
  font-weight: 400;
  font-size: 10px !important;
  color: rgba(20, 115, 230, 0.7215686275);
  text-transform: capitalize;
}

.customer-tickt-list .card-header {
  color: #0d6efd;
  font-weight: 600;
}

.icon-contact {
  color: #0d6efd;
}

.text-orange {
  color: #f64701 !important;
}
