

/* 
:root {
  --bs-body-font-family: "Noto Sans JP", sans-serif !important;
}
:root p {
  margin-bottom: 0 !important;
}
:root .card {
  box-shadow: rgba(10, 10, 10, 0.1) 0px 0px 5px 0px;
  border-radius: 0 !important;
}
:root .card .card-header {
  border-radius: 0 !important;
}
:root .card .card-footer {
  border-radius: 0 !important;
}
*, *::before, *::after {
  box-sizing: border-box;
} */
html, body {
  font-family: "Noto Sans JP", sans-serif;
  background: #f3f6fc;
  overflow: hidden;
  text-decoration: none;
  height: 100dvh;
}
.hed_left p {
  text-transform: uppercase;
  font-weight: bold;
}
.setting-body {
  overflow: hidden;
  overflow-y: auto;
}
.fw-semibold {
  font-weight: 600 !important;
}
.breadcrumb .breadcrumb-item {
  display: flex;
  gap: inherit;
  align-items: center;
  padding-left: 0;
  line-height: 1;
  font-size: 13px !important;
  text-transform: uppercase;
}
.breadcrumb .breadcrumb-item .active {
  color: #212529bf;
}
.breadcrumb .link-body-emphasis {
  color: #0b3363;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.toast {
  border: 2px solid white !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px 0px !important;
}
.toast .circle-toast {
  height: 2.4rem;
  width: 2.4rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 8px 0px;
  border: none;
}
.toast .circle-toast2 {
  height: 1.6rem;
  width: 1.6rem;
  color: white;
}
.toast .circle-toast2 span, .toast .circle-toast2 i {
  font-size: 17px;
}
.toast a {
  color: #ffffff !important;
  text-decoration: none;
}
.toast a:hover {
  text-decoration: underline !important;
}
.toast .small {
  font-size: 10px !important;
  text-shadow: none;
}
.toast p {
  font-size: 11px !important;
  text-shadow: #000000 -1px 1px 0;
  color: white;
}
.toast-close-main {
  position: absolute;
  right: 0;
  top: 0;
  background: #134a7521;
  border-radius: 0 0 0 7px;
}
.btn-closes {
  border: none;
  color: white !important;
  opacity: 50%;
}
.btn-closes:hover {
  opacity: 100%;
}
.btn-closes:focus {
  border: none !important;
}
.toaster-information, .toaster-notification {
  background: #2196f3;
  background: linear-gradient(274deg, #2196f3e5 0%, #cae6fc 100%);
}
.toaster-information .circle-toast, .toaster-notification .circle-toast {
  background: #0f82de6b;
}
.toaster-information .circle-toast2, .toaster-notification .circle-toast2 {
  background: #2196f3;
}
.toaster-warning {
  background: #ff9800;
  background: linear-gradient(274deg, #ff9800 0%, #ffe2b8 100%);
}
.toaster-warning .circle-toast {
  background: #f1ae4ce3;
}
.toaster-warning .circle-toast2 {
  background: #ff9800;
}
.toaster-success {
  background: #04AA6D;
  background: linear-gradient(274deg, #04AA6D 0%, #a3ffdd 100%);
}
.toaster-success .circle-toast {
  background: #53c99e9e;
}
.toaster-success .circle-toast2 {
  background: #04AA6D;
}
.toaster-error {
  background: #ff594dde;
  background: linear-gradient(274deg, #ff594dde 0%, #ffcfcc 100%);
}
.toaster-error .circle-toast {
  background: #d35b4a57;
}
.toaster-error .circle-toast2 {
  background: #ff594dde;
}
.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}
/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.S-sub-m .small-title {
  color: #1473e6;
  font-size: 12px;
  font-weight: 600;
}
.S-sub-m .r_name {
  color: #626263;
  font-size: 13px !important;
}
.S-sub-m .r_date {
  color: #939393;
  font-size: 12px;
  margin-right: 15px;
}
.S-sub-m .r_content .r_name p {
  color: #626263;
  font-size: 13px !important;
}
.sub-footer {
  border-right: 1px solid #dee2e6;
  margin-right: 1rem;
}
.sub-footer:last-child {
  border-right: none;
  margin-right: 0;
}
.bg-s1 {
  background: #f3f6fc !important;
}
.s-m-card:active, .s-m-card:focus, .s-m-card:hover {
  cursor: pointer;
  box-shadow: #1473e69e 0px 0.5px 1px 0px inset;
  transition: 0.7s;
}
.error {
  color: #1473e6;
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
}
.overflow-y {
  overflow: hidden;
  overflow-y: auto;
}
.h-30 {
  height: 30px !important;
}
.h-35 {
  height: 35px !important;
}
.btm-19 {
  bottom: 19px !important;
}
.sub-main {
  padding: 7px 13px 7px 63px;
}
.material-symbols-outlined {
  font-size: 1.2rem;
}
.hed-btnprimary {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #0b3363;
  font-family: Arial;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.hed-btnprimary .material-symbols-outlined {
  font-size: 1.2rem;
}
.hed-btnprimary:hover {
  background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
  background-color: #f6f6f6;
}
.hed-btnprimary:active {
  position: relative;
  top: 1px;
  transition: 0.1s;
}
.hed-btnprimary2 {
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  gap: 0.5rem;
  color: #fff;
  border-radius: 0;
  padding: 10px 12px;
  font-weight: bold;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  justify-content: center;
  align-items: center;
  background: #0b3363;
  background: linear-gradient(0deg, #1473e6 0%, #0c19b4 100%);
  border: none;
}
.hed-btnprimary2 span {
  font-size: 14px;
}
.hed-btnprimary2:hover {
  background: #1473e6;
  background: linear-gradient(0deg, #1473e6 0%, #027efb 100%);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 16px 0px;
  color: #ffffff;
  background-color: #1473e6;
  border-color: #1473e6;
}
.seach-drop {
  font-size: 13px;
  background: unset;
  padding: 0.4rem;
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b3363;
  border: unset;
}
.sub-master {
  padding: 10px;
  min-height: 100px;
  width: 100%;
}
.sub-master .icon-master span {
  font-size: 35px;
  color: #1473e6;
}
.sub-master .name-master {
  margin-top: 6px;
  color: #242424;
  font-size: 15px;
  font-weight: 500;
}
.sub-master .name-master p {
  margin-bottom: 0.1rem;
}
.sub-master .name-master .small {
  font-size: 13px;
  color: #626263;
  font-weight: 400;
}
.sub-master:hover {
  cursor: pointer;
  border-radius: 0;
  background: #ffff;
  align-items: center;
  box-shadow: 0 1.875em 20px -30px rgba(57, 48, 40, 0.68);
  transform: translateY(-5px);
  transition: all 0.5s;
}
.btnwhatsapp {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: inset 0px 1px 0px 0px #47ff31d9;
  background: linear-gradient(to bottom, #abfaa2 5%, #11b000 100%);
  background-color: #35c126;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #0000;
}
.btnwhatsapp i, .btnwhatsapp .material-symbols-outlined {
  font-size: 1.2rem;
}
.btnwhatsapp:hover {
  background: linear-gradient(to bottom, #19900c 5%, #70ff61 100%);
  background-color: #35c126;
}
.btnwhatsapp:hover span {
  background: linear-gradient(to bottom, #19900c 5%, #70ff61 100%);
  background-color: #35c126;
}
.btnwhatsapp:active {
  position: relative;
  top: 1px;
  transition: 0.1s;
}
.btncall {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: inset 0px 1px 0px 0px #1473e6;
  background: linear-gradient(to bottom, #9ba6d6 5%, #1473e6 100%);
  background-color: #1473e6;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #0000;
}
.btncall i, .btncall .material-symbols-outlined {
  font-size: 1.2rem;
}
.btncall:hover {
  background: linear-gradient(to bottom, #2657cf 5%, #0b3363 100%);
  background-color: #0b3363;
}
.btncall:hover span {
  background: linear-gradient(to bottom, #2657cf 5%, #0b3363 100%);
  background-color: #0b3363;
}
.btncall:active {
  position: relative;
  top: 1px;
  transition: 0.1s;
}
.dashboard {
  background: #f3f6fc;
  height: 100dvh;
}
.shadow-3d {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 6px -3px inset;
}
.p-5 {
  padding: 5px !important;
}
.fs-5 {
  font-size: 5px !important;
}
/* .fs-6 {
  font-size: 6px !important;
} */
.fs-7 {
  font-size: 7px !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.card-body-height {
  min-height: 66vh;
  max-height: 70vh;
  overflow: hidden;
}
.btn-hover-light {
  color: black !important;
  background-color: white !important;
}
.btn-hover-light:hover, .btn-hover-light:focus {
  color: #1473e6 !important;
  background-color: #f8f9fa !important;
}
.vh-46 {
  height: 46vh !important;
}
.vh-51 {
  height: 51vh !important;
}
.vhm-52 {
  max-height: 52vh !important;
}
.vhm-53 {
  max-height: 53vh !important;
}
.vhm-54 {
  max-height: 54vh !important;
}
.vh-55 {
  height: 55vh !important;
}
.vh-58 {
  height: 58vh !important;
}
.vh-60 {
  height: 60vh !important;
}
.vhm-61 {
  max-height: 61vh !important;
}
.vh-62 {
  max-height: 62vh !important;
}
.vh-63 {
  max-height: 63vh !important;
}
.vh-64 {
  height: 64dvh !important;
}
.vh-65 {
  height: 65dvh !important;
}
.vh-66 {
  height: 66dvh !important;
}
.vh-68 {
  height: 68dvh !important;
}
.vh-69 {
  height: 69dvh !important;
}
.vhm-65 {
  max-height: 65dvh !important;
}
.vhm-68 {
  max-height: 68dvh !important;
}
.vh-73 {
  height: 73dvh !important;
}
.vh-74 {
  height: 74dvh !important;
}
.vh-75 {
  height: 75dvh !important;
}
.vh-76 {
  height: 76dvh !important;
}
.vh-77 {
  height: 77dvh !important;
}
.vh-78 {
  height: 78dvh !important;
}
.vh-79 {
  height: 79dvh !important;
}
.vh-80 {
  height: 80dvh !important;
}
.vh-84 {
  height: 84dvh !important;
}
.vh-85 {
  height: 85dvh !important;
}
.ps-7 {
  padding-left: 7px !important;
}
.ps-10 {
  padding-left: 10px !important;
}
.ps-13 {
  padding-left: 13px !important;
}
.ps-12 {
  padding-left: 12px !important;
}
.d-flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.mdc-list-item__primary-text {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.shadow-inset {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 6px -3px inset;
}
.cust_hed {
  padding-bottom: 0.5rem;
}
.form-control, .form-select {
  border-radius: 0 !important;
  font-size: 12px !important;
}
.form-select option {
  border-radius: 0 !important;
}
.cust_icon {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b3363;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 0px;
}
.text-no-data {
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
  color: #1473e6;
  text-align: center;
}
.nav-tabs .nav-item .nav-link {
  border-radius: 0 !important;
}
.form-label {
  font-size: 13px !important;
}
a, p {
  text-decoration: none !important;
}
.list-unstyled li {
  cursor: pointer;
}
.dismiss {
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0;
  text-align: center;
  color: #1473e6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dismiss:hover, .dismiss:focus {
  background: #1473e6;
  color: #ffff;
  font-size: 23px;
  transition: all 0.6s;
}
.btn-outline-mainprimary {
  color: Primary_1;
  border-color: Primary_1;
  height: 35px;
}
.btn-outline-mainprimary:hover {
  color: #ffff;
  background-color: Primary_1;
  border-color: Primary_1;
}
.btn-primary-primary {
  color: #ffff;
  background-color: Primary_1;
  border-color: Primary_1;
}
.btn-primary-primary:hover {
  color: #ffff;
  background-color: #006fff;
  border-color: #006fff;
}
.btn-outline-primary-primary {
  color: Primary_1;
  border-color: Primary_1;
}
.btn-outline-primary-primary:hover {
  color: #ffff;
  background-color: #006fff;
  border-color: #006fff;
}
.me-20 {
  margin-right: 20px;
}
.w-50px {
  width: 50px;
}
.custom-tooltip {
  font-size: 11px !important;
}
.r_action {
  display: flex;
  align-items: center;
}
.bg-p1 {
  background: #0b3363 !important;
}
.bg-p2 {
  background: #1473e6;
}
.form-control:focus {
  color: #242424;
  background-color: #fff;
  border-color: #1473e6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0) !important;
}
.btn-primary2 {
  font-family: "Noto Sans JP", sans-serif;
  background-color: #1473e6;
  border-color: #1473e6 !important;
  color: #ffff;
}
.btn-primary2:hover {
  color: #ffff;
  background-color: #0b3363;
  border-color: #0b3363;
}
.menu-contents-right {
  position: relative;
}
.content-form {
  height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 1rem;
}
.sider-hed {
  font-size: 1rem;
  text-transform: uppercase;
}
.form-btn-side {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 5px;
  background: white;
}
.small-title {
  color: #c9c9c9;
  font-size: 12px;
  font-weight: 600;
}
h1 {
  font-family: Poppins;
  font-size: 23px;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.mb-22 {
  margin-bottom: 22px;
}
.form-select:focus {
  border-color: #0b3363;
  outline: 0;
  box-shadow: none !important;
}
.border-primary-2 {
  border-color: #1473e6 !important;
}
.table-primary-1 {
  color: #ffff;
  border-color: #0b3363;
  background: #0b3363;
}
.btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0);
}
.btn-primary-1 {
  color: #ffff;
  border: 1px solid #1473e6;
  border-radius: 0;
  background: #1473e6;
  font-family: "Noto Sans JP", sans-serif;
}
.btn-primary-1:focus {
  border-color: #0b3363;
  outline: 0;
  box-shadow: none;
}
.btn-primary-1:hover {
  color: #1473e6;
  background: #ffff;
}
.btn-select {
  color: #242424;
  border: 1px solid #c9c9c9;
  border-radius: 0;
}
.btn-select:focus {
  border-color: #0b3363;
  outline: 0;
  box-shadow: none;
}
.btn-outline-select {
  color: #242424;
  border: 1px solid #c9c9c9;
  border-radius: 0;
}
.btn-outline-select:hover {
  color: #ffff;
  background-color: #1473e6;
  border-color: #1473e6;
}
.d-flex-p {
  display: flex;
  align-content: center;
  align-items: center;
}
.r_content, .r_name {
  display: flex;
  flex-direction: column;
}
.r_icons {
  color: #939393;
  font-size: 12px;
}
/* Active state */
.search-bar {
  width: 100%;
  max-width: 30em;
  display: flex;
}
.search-bar input {
  transition: all 0.25s ease-out;
  width: 3em;
  height: 3em;
  background: transparent;
  border-radius: 1.5em;
  box-shadow: 0 0 0 0.4em #171717 inset;
  padding: 0.75em;
  transform: translate(0.5em, 0.5em) scale(0.5);
  transform-origin: 100% 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.search-bar input:invalid:not(:focus) {
  cursor: pointer;
}
.search-bar input:focus {
  width: 100%;
  outline: transparent;
  background: #fff;
  border-radius: 0.375em 0 0 0.375em;
  box-shadow: 0 0 0 0.1em #d9d9d9 inset;
  transform: scale(1);
}
.search-bar input:focus + .search-btn {
  background: #2762f3;
  border-radius: 0 0.375em 0.375em 0;
  transform: scale(1);
}
.search-bar input:focus + .search-btn:before {
  opacity: 1;
}
.search-bar input:focus + .search-btn:after {
  opacity: 1;
}
.search-bar input:focus + .search-btn:hover {
  background: #0c48db;
}
.search-bar input:focus + .search-btn:active {
  transform: translateY(1px);
}
.search-bar input:valid {
  width: 100%;
  background: #fff;
  border-radius: 0.375em 0 0 0.375em;
  box-shadow: 0 0 0 0.1em #d9d9d9 inset;
  transform: scale(1);
}
.search-bar input:valid + .search-btn {
  background: #2762f3;
  border-radius: 0 0.375em 0.375em 0;
  transform: scale(1);
}
.search-bar input:valid + .search-btn:before {
  opacity: 1;
}
.search-bar input:valid + .search-btn:after {
  opacity: 1;
}
.search-bar input:valid + .search-btn:hover {
  background: #0c48db;
}
.search-bar input:valid + .search-btn:active {
  transform: translateY(1px);
}
.search-bar input:valid:not(:focus) + .search-btn:focus {
  background: #0c48db;
}
.search-bar input:not(:focus) + .search-btn:focus {
  outline: transparent;
}
.search-btn {
  transition: all 0.25s ease-out;
  width: 3em;
  height: 3em;
  cursor: pointer;
  background: #171717;
  border-radius: 0 0.75em 0.75em 0Array/0Array 1.5em 1.5em 0;
  padding: 0.75em;
  position: relative;
  transform: translate(0.25em, 0.25em) rotate(45deg) scale(0.25, 0.125);
  transform-origin: 0 50%;
}
.search-btn:before {
  transition: all 0.25s ease-out;
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 0 0.2em #f1f1f1 inset;
  top: 0.75em;
  left: 0.75em;
  width: 1.2em;
  height: 1.2em;
}
.search-btn:after {
  transition: all 0.25s ease-out;
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  background: #f1f1f1;
  border-radius: 0 0.25em 0.25em 0;
  top: 51%;
  left: 51%;
  width: 0.75em;
  height: 0.25em;
  transform: translate(0.2em, 0) rotate(45deg);
  transform-origin: 0 50%;
}
.search-btn span {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 1px;
}
* {
  scrollbar-color: #f3f6fc;
}
::-webkit-scrollbar, ::-webkit-scrollbar-corner {
  background: transparent;
  height: 12px;
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background: content-box currentColor;
  border: 2px solid transparent;
  border-radius: 8px;
  color: #dadce0;
  min-height: 48px;
  min-width: 48px;
}
:hover::-webkit-scrollbar-thumb {
  color: #80868b;
}
::-webkit-scrollbar-thumb:active {
  color: #5f6368;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
.loading {
  position: fixed;
  top: 67px;
  left: 49px;
  width: 96vw;
  height: 91vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background: #ffffff24;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 999999;
}
.loading .spinner {
  width: 11.2px;
  height: 11.2px;
  animation: spinner-o824ag 1s infinite linear;
}
.loading .spinner div {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1473e6;
  border-radius: 50%;
  animation: spinner-vse6n7 1.25s infinite ease;
}
.loading .spinner div:nth-child(1) {
  --rotation: 90;
}
.loading .spinner div:nth-child(2) {
  --rotation: 180;
}
.loading .spinner div:nth-child(3) {
  --rotation: 270;
}
.loading .spinner div:nth-child(4) {
  --rotation: 360;
}
@keyframes spinner-vse6n7 {
  0%, 100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }
  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}
@keyframes spinner-o824ag {
  to {
    transform: rotate(360deg);
  }
}
.loader-hide {
  opacity: 0;
  visibility: hidden;
}
.body {
  font-family: "Poppins";
  font-style: normal;
}
/* .wapper_sub {
  margin-left: 60px;
} */
.color_b {
  color: #1473e6 !important;
}
.cust_content {
  height: 58vh;
  overflow-y: auto;
}
.min-33h {
  min-height: 33px;
  max-height: 33px;
}
.header_role {
  background: white;
  /* padding: 7px 13px 7px 63px; */
  border-bottom: 2px solid #1473e6;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
}
.item {
  width: auto;
  height: auto;
  margin: 10px auto;
  border: 1px solid #eaeaea;
  padding: 10px;
}
.fb-item {
  width: auto;
  height: auto;
  margin: 10px auto;
  border: 1px solid #eaeaea;
  padding: 10px;
}
.gravatar {
  width: 100px;
  height: 90px;
}
.gravatar-title {
  width: 500px;
  padding: 10px;
  height: 80px;
}
.first-section-wrapper, .second-section-wrapper {
  width: 100%;
  height: auto;
  flex: 1;
}
.first-section-wrapper {
  display: inline-flex;
}
.second-section-wrapper {
  margin-top: 30px;
}
.item-with-background {
  background: #121212;
  padding: 10px;
}
.loader {
  line-height: 1;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background: #eff1f6 no-repeat;
  background-color: #eff1f6;
  line-height: 1;
  overflow: hidden;
  position: relative;
}
.content-loaded-image {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-color: #aeaeae;
  margin: 5px;
  border-radius: 50%;
}
.gravatar-title-name {
  margin-top: 0;
  width: 200px;
  border-radius: 0;
  height: 15px;
  margin-bottom: 15px;
  font-size: 18px;
}
.gravatar-description {
  width: 170px;
  border-radius: 0;
  height: 15px;
  font-size: 12px;
}