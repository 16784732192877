.row2222 {
  background: #fff;
  position: sticky;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.no-outline {
  flex: 1;
  height: 40px;
  background: transparent;
  border: 0;
  font-size: 18px;
  color: #333;
  padding: 0px 10px;
  font-size: 15px;
}

.result-box {
  background: #fff;
  border-radius: 5px;
  max-height: 420px;
  overflow-y: scroll;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.result-box::-webkit-scrollbar {
  display: none;
}
.result-box ul {
  padding: 15px 10px;
}
.result-box ul li {
  list-style: none;
  border-radius: 3px;
  padding: 10px 10px;
}

.result-box ul li:hover {
    background:#F6F7F9;
  }
  