*,
*::before,
*::after {
  box-sizing: border-box;
}

.incomecall-phone {
  position: fixed;
  top: 74px;
  right: 25px;
  width: 250px;
  background: #012a67;
  color: white;
  border-radius: 4px;
  text-align: center;
  font-size: 35px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
    rgb(0 0 0 / 10%) 0px 2px 4px 0px,
    rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
  z-index: 99999;
}

.incomecall_main {
  position: fixed;
  bottom: 64px;
  right: 1rem;
  background: transparent linear-gradient(180deg, #0c4fa5 0%, #9ae6ff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 15px 15px rgb(121 88 88 / 16%);
  border-radius: 62px;
  z-index: 999;
}
.incomecall_main .call_names p {
  color: #7ed0ea;
  font-size: 15px;
  margin-bottom: 14px;
}
.incomecall_main .call_names h1 {
  color: white;
  font-size: 15px;
}
.incomecall_main .call-animation {
  background: #0c4fa5;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  animation: call 1.5s ease infinite;
  color: aliceblue;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0) 0px 1px 3px, rgba(0, 0, 0, 0.14) 0px 1px 2px;
}

.call-end {
  width: 20px;
  height: 20px;
  background: #da291c 0% 0% no-repeat padding-box;
  border-radius: 10%;
  color: white;
}
.call-end2 {
  width: 20px;
  height: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10%;
  color: white;
}

.call-end3 {
  width: 20px;
  height: 20px;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 10%;
}

.call-end2:hover {
  background: #a8a8a8 0% 0% no-repeat padding-box;
}
.call-end:hover {
  background: #eb2f32 0% 0% no-repeat padding-box;
}

.call-start {
  width: 37px;
  height: 37px;
  background: #40b750 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: white;
}

.call-start:hover {
  background: #1bdf36 0% 0% no-repeat padding-box;
}

.call-transfer {
  width: 47px;
  height: 47px;
  background: #4064b7 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: white;
}
.call-transfer:hover {
  background: #4276ee 0% 0% no-repeat padding-box;
}

@keyframes call {
  15% {
    box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.031372549);
  }
  25% {
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.031372549),
      0 0 0 15px rgba(255, 255, 255, 0.031372549);
  }
  30% {
    box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.031372549),
      0 0 0 19px rgba(255, 255, 255, 0.031372549);
  }
}
@keyframes calling {
  15% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.031372549);
  }
  25% {
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.031372549),
      0 0 0 18px rgba(255, 255, 255, 0.031372549);
  }
  30% {
    box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.031372549),
      0 0 0 25px rgba(255, 255, 255, 0.031372549);
  }
}
.callattend_main {
  justify-content: space-between;
  position: fixed;
  bottom: 57px;
  width: 317px;
  right: 1rem;
}

.call-btns {
  font-size: 15px;
  color: white;
}

.call-btns:hover {
  color: #1d1f20;
}

.solid {
  font-family: "Font Awesome 6 Free" !important;
  font-weight: 900 !important;
}

.dialer-main {
  width: 306px;
  border-radius: 20px;
  height: 70vh;
  position: absolute;
  top: 100px;
  right: 15px;
  background: white;
  color: #000;
  box-shadow: -6px 1px 9px 0 rgb(65 113 179 / 31%);
  text-align: left;
  text-align: center;
  z-index: 99999;
}
.dialer-main .filter {
  width: 100%;
  height: 70vh;
  border-radius: 20px;
}
.dialer-main .filter p {
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  color: #0c4fa5;
}
.dialer-main .filter form .form-label {
  font-size: 14px;
  color: #242424;
}
.dialer-main .filter form .form-control {
  font-size: 14px;
}
.dialer-main .filter form .form-select {
  font-size: 14px;
}
.dialer-main .filter .fillter_form2 {
  height: 65vh;
  overflow: hidden;
  margin-bottom: 0.5rem;
  overflow-y: scroll;
  text-align: left;
}
.dialer-main .filter .fillter_form2::-webkit-scrollbar {
  display: none;
}

.active-d {
  right: 0;
}

.dial-nav {
  position: fixed;
  bottom: 50 !important;
  background: white;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
}
.dial-nav .active {
  color: #0c4fa5 !important;
  background: unset !important;
}
.dial-nav .nav-item .nav-link {
  color: #c2c2c2;
  font-size: 8px;
}
.dial-nav .nav-item .nav-link i {
  font-size: 20px;
  margin-bottom: 5px;
}
.dial-nav .nav-item .nav-link:hover {
  color: #0c4fa5;
}

.nav-width {
  width: 65px;
}

.dialer-btn-main {
  z-index: 9999;
  position: absolute;
  top: -35px;
  right: 130px;
  width: 50px;
  height: 50px;
  background: #0c4fa5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  border-radius: 50%;
  background: #0c4fa5;
  align-items: center;
  justify-content: center;
  color: white;
  border: unset;
}
.dialer-btn-main:hover {
  background-color: #0e174c;
}

.avatar-side-letter {
  position: absolute;
  top: 25px;
  left: 20px;
}
.avatar-side-letter,
p {
  font-size: 10px;
}

.icons-dialer {
  padding: 20px 25px 5px 25px !important;
}

.dialer-sub {
  padding: 0px 25px 0px 25px !important;
}

.dial-pad .dial-pad-sub .icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.dial-pad .dial-pad-sub .icons li {
  width: 49px;
  height: 32px;
  margin-left: 30px;
  margin-bottom: 17px;
  float: left;
  color: #0c4fa5;
  font-size: 22px;
  font-weight: bold;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  text-shadow: 0px -1px #222;
  transition: 0.1s;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.dial-pad .dial-pad-sub .icons li:nth-child(3n + 1) {
  margin-left: 0;
}
.dial-pad .dial-pad-sub .icons li:active {
  background: #0c4fa5;
  color: #fff;
  transform: scale(1.2);
}
.dial-pad .dial-pad-sub .icons li:hover {
  cursor: pointer;
  background: #0c4fa5;
  color: white;
}
.dial-pad .dial-pad-sub .icons li:hover span {
  color: white;
}

.dial-pad-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f9fa;
  padding: 30px;
  padding-left: 20px;
  padding-right: 28px;
  border-top-right-radius: 26px;
  border-top-left-radius: 25px;
}

.dialerInput {
  border: 0 !important;
  height: 58px !important;
  font-size: 21px !important;
  font-weight: bold !important;
  text-indent: 8px !important;
  color: #0c4fa5 !important;
}
.dialerInput:focus-visible {
  border: unset !important;
}
.dialerInput:focus {
  color: #0c4fa5;
}

.dial-bck {
  background: unset;
  border: 0;
  font-size: 31px !important;
  height: 45px;
  letter-spacing: 0px;
  color: #707070;
}
.dial-bck:hover {
  color: #0c4fa5;
}

.sub {
  letter-spacing: 0px;
  color: #707070;
  font-size: 14px;
}

.star2 {
  font-size: 28px !important;
  padding-bottom: 10px !important;
}

.hash0 {
  padding-bottom: 20px !important;
}

.dialer-badge {
  border-radius: 50%;
  padding: 4px;
  transform: translate(-88%, -26%);
  background-color: #10ce4f;
}

.callicon {
  position: fixed;
  bottom: 85px;
  width: 55px;
  height: 50px;
  right: 0;
  background: #0c4fa5;
  box-shadow: 0px 15px 15px rgba(121, 88, 88, 0.16);
  border-radius: 78% 0 0 78%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}
.callicon::after {
  content: "";
  width: 20px;
  position: absolute;
  height: 20px;
  top: -8px;
  right: -11px;
  -webkit-clip-path: polygon(
    49% 0%,
    100% 0,
    100% 35%,
    100% 100%,
    68% 100%,
    32% 100%,
    0 100%,
    0% 43%,
    32% 23%
  );
  clip-path: polygon(
    49% 0%,
    100% 0,
    100% 35%,
    100% 100%,
    68% 100%,
    32% 100%,
    0 100%,
    0% 43%,
    32% 23%
  );
  background: #0c4fa5;
}
.callicon::before {
  content: "";
  width: 20px;
  position: absolute;
  height: 20px;
  bottom: -11px;
  right: -12px;
  -webkit-clip-path: polygon(
    49% 0%,
    100% 0,
    100% 40%,
    100% 100%,
    60% 100%,
    38% 100%,
    25% 63%,
    0% 42%,
    0 0
  );
  clip-path: polygon(
    49% 0%,
    100% 0,
    100% 40%,
    100% 100%,
    60% 100%,
    38% 100%,
    25% 63%,
    0% 42%,
    0 0
  );
  background: #0c4fa5;
} /*# sourceMappingURL=dialer.css.map */

.image-paragraphs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
  padding-bottom: 9px;
}
.image-paragraphs p {
  color: white;
  font-family: sans-serif;
  font-size: 12.5px;
  padding-left: 10px;
  font-weight: 600;
}

.image-paragraphs img {
  margin-right: 10px;
  width: 20px;
}
